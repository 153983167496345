import React, { useContext } from "react";
import { Link } from 'react-router-dom';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { AuthContext } from "../../../context";
import AuthService from '../../../auth/AuthService';

import icoLogoHorizontal from '../../../assets/img/ic-logo.svg';
import adacLogoHorizontal from '../../../assets/img/logo-adac.png';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
        container: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        dense: {
            marginTop: theme.spacing(2),
        },
        menu: {
            width: 200,
        },
        button: {
        },
        extendedIcon: {
            marginRight: theme.spacing(1),
        },
        companyLogo: {
            width: '80%',
        },
        devBy: {
            fontSize: "14px",
            fontWeight: 600,
            color: 'rgba(0, 0, 0, 0.54)'
        },
        adacLogo: {
            width: '90px',
        },
    }),
);

export default function LoginPage() {
    const { signIn, setAdmin } = useContext(AuthContext);
    const Auth = new AuthService();

    const classes = useStyles();

    let loginSchema = yup.object().shape({
        email: yup.string().email().required(),
        password: yup.string().required()
    });

    const { register, handleSubmit, errors } = useForm({
        validationSchema: loginSchema
    });


    const onSubmit = (values) => {
        Auth.login(values.email, values.password)
            .then(res => {
                let infoRes = window.resFromAuth;

                if (res === 'inactive') {
                    this.handleErrorLogin('Sua conta está inativa, entre em contato com a matriz.');
                }
                if (infoRes === 'record not found') {
                    this.handleErrorLogin('Conta não existe, entre em contato com a matriz.');
                }
                if (infoRes['error'] === 'invalid_credentials') {
                    this.handleErrorLogin('Erro ao logar, entre em contato com a matriz.');
                } else if (infoRes['error'] === 'record not found') {
                    this.handleErrorLogin('Usuário não existe.');
                } else if (infoRes['error'] === 'could_not_create_token') {
                    this.handleErrorLogin('Ocorreu um erro, tente novamente.');
                } else {
                    localStorage.setItem('@Bortolini::is_admin', res.Type == "receptionist" ? false : true);
                    signIn(res.Token, true);
                    setAdmin(res.Type == "receptionist" ? false : true)
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    const handleSignup = () => {
        console.log('you are signing up');
    }

    return (
        <div className={classes.root}>
            <Box mt={5} px={2}>
                <Grid container spacing={3}
                    direction="row"
                    justify="center"
                    alignItems="center"
                >
                    <Grid item xs={12} sm={8} md={6} lg={4}>
                        <Grid xs={12} container
                            direction="row"
                            justify="center"
                            alignItems="center"
                        >
                            <img className={classes.companyLogo} src={icoLogoHorizontal} />
                        </Grid>
                        <br />
                        <br />
                        <Grid xs={12} container
                            direction="row"
                            justify="flex-end"
                            alignItems="center"
                        >
                            <Grid xs={12} container
                                direction="row"
                                justify="flex-end"
                                alignItems="center"
                            >
                                <span className={classes.devBy}>Desenvolvido por</span>
                            </Grid>
                            <Grid xs={12} container
                                direction="row"
                                justify="flex-end"
                                alignItems="center"
                            >
                                <a href="https://adac.dev/" target="_blank">
                                    <img className={classes.adacLogo} src={adacLogoHorizontal} />
                                </a>
                            
                            </Grid>
                        </Grid>
                        <br />
                        <br />
                        <Paper className={classes.paper}>
                            <Typography variant="h3" gutterBottom>
                                Login
                            </Typography>
                            <form onSubmit={handleSubmit(onSubmit)} className={classes.container}>
                                <TextField
                                    name="email"
                                    inputRef={register}
                                    label="Email"
                                    className={classes.textField}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                    error={errors.email ? true : false}
                                />

                                <TextField
                                    name="password"
                                    type="password"
                                    inputRef={register}
                                    label="Senha"
                                    className={classes.textField}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                    error={errors.email ? true : false}
                                />
                                <div style={{flexGrow: 1, padding: 7}}>
                                    <Grid container spacing={2} direction='row'>
                                        <Grid item xs={12}>
                                            <Button className={classes.button} type="submit" variant="outlined" aria-label="delete" color="primary" fullWidth={true}>
                                                Login
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    {/* <Grid container spacing={2}>
                                        <Grid item xs>
                                            <Button className={classes.button} variant="outlined" aria-label="delete" color="primary" fullWidth={true}>
                                                Conta do Facebook
                                            </Button>
                                        </Grid>
                                        <Grid item xs>
                                            <Button className={classes.button} variant="outlined" aria-label="delete" color="primary" fullWidth={true}>
                                                Conta do Google
                                            </Button>
                                        </Grid>
                                    </Grid>  */}
                                    <Grid container spacing={2}>
                                        {/* <Grid item xs>
                                            <Button className={classes.button} variant="text" aria-label="delete" color="primary" fullWidth={true} style={{textTransform: 'lowercase'}}>
                                                Esqueceu a senha?
                                            </Button>
                                        </Grid> */}
                                        {/* <Grid item xs>
                                            <Link to="/signup">
                                                <Button className={classes.button} variant="text" aria-label="delete" color="primary" fullWidth={true} style={{textTransform: 'lowercase'}} onClick={handleSignup}>
                                                    Cadastre-se
                                                </Button>
                                            </Link>
                                        </Grid> */}
                                    </Grid> 
                                </div>                                
                            </form>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
} 