import React from 'react';
import Axios from 'axios';
import { isLoading, toastInfo, convertDateISOToFormatVisualDateTime, numberDayToString, convertDateISOToBROnlyHour } from '../../../util/helpers';
import { NavLink } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

class RealtorRestriction extends React.Component {
    constructor() {
        super();
        isLoading('block');
        this.state = {
            data: [],
            dataPeriodic: [],
            dataPonctual: []
        };
        this.limitPage = 1000;
        this.deleteRow = this.deleteRow.bind(this);
        this.handleGetInfo = this.handleGetInfo.bind(this);
    }

    handleGetInfo() {
        Axios.get(
            `realtor-restriction?page=0&limit=${this.limitPage}&order=id asc`,
        ).then(response => {
            let realData = response.data.List || [];
 
            let allPeriodicsForRealtor = [];
            let allPonctualForRealtor = [];
            realData.map((el) => {
                if (this.props.restrictionsSelected != undefined) {
                    this.props.restrictionsSelected.map((elIn) => {
                        if (el.ID == elIn.ID && el.Weekday == null) allPonctualForRealtor.push(el);
                        if (el.ID == elIn.ID && el.Weekday != null) allPeriodicsForRealtor.push(el);
                    });
                }
            });

            this.setState({
                data: realData,
                dataPeriodic: allPeriodicsForRealtor,
                dataPonctual: allPonctualForRealtor
            });

            isLoading('none');
        }).catch(error => {
            toastInfo('error', 'custom')
            console.log("erro", error, error.response);
            isLoading('none');
        });
    }
    componentWillMount() {
        this.handleGetInfo();
    }
    render() {
        const { dataPeriodic, dataPonctual } = this.state;
        let dataTablePeriodic = dataPeriodic || [];
        const rowsPeriodic = dataTablePeriodic.slice(0).reverse().map(function (row) {
            return (
                <CSSTransition
                    key={row.ID}
                    timeout={{ enter: 0, exit: 100 }}
                    classNames="animation__toRight">
                    <tr key={row.ID} data-id={row.ID} className="js-tr-data">
                        <td className="js-td-search">{row.Reason}</td>
                        <td className="js-td-search">{numberDayToString(row.Weekday)}</td>
                        <td className="js-td-search">{convertDateISOToBROnlyHour(row.StartAt)}</td>
                        <td className="js-td-search">{convertDateISOToBROnlyHour(row.EndAt)}</td>
                        <td className="td__action">
                            <div className="tooltip">
                                <NavLink className="edit" to={`/restricao-do-corretor/edit/` + row.ID}></NavLink>
                                <span className="tooltiptext">Editar</span>
                            </div>
                            <div className="tooltip">
                                <font
                                    className="delete"
                                    onClick={(e) => { this.deleteRow(e) }}>
                                </font>
                                <span className="tooltiptext">Excluir</span>
                            </div>
                        </td>
                    </tr>
                </CSSTransition>
            )
        }.bind(this));

        let dataTablePonctual = dataPonctual || [];
        const rowsPonctual = dataTablePonctual.slice(0).reverse().map(function (row) {
            return (

                <CSSTransition
                    key={row.ID}
                    timeout={{ enter: 0, exit: 100 }}
                    classNames="animation__toRight">
                    <tr key={row.ID} data-id={row.ID} className="js-tr-data">
                        <td className="js-td-search">{row.Reason}</td>
                        <td className="js-td-search">{convertDateISOToFormatVisualDateTime(row.StartAt)}</td>
                        <td className="js-td-search">{convertDateISOToFormatVisualDateTime(row.EndAt)}</td>
                        <td className="td__action">
                            <div className="tooltip">
                                <NavLink className="edit" to={`/restricao-do-corretor/edit/` + row.ID}></NavLink>
                                <span className="tooltiptext">Editar</span>
                            </div>
                            <div className="tooltip">
                                <font
                                    className="delete"
                                    onClick={(e) => { this.deleteRow(e) }}>
                                </font>
                                <span className="tooltiptext">Excluir</span>
                            </div>
                        </td>
                    </tr>
                </CSSTransition>
            )
        }.bind(this));
        return (
            <Container maxWidth="lg">
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant="h6">Restrições de não atendimento</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <h2 className="text-center">Periódicas</h2>
                        <div className="general__table">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Motivo</th>
                                        <th>Dia da Semana</th>
                                        <th>Hora Início</th>
                                        <th>Hora Fim</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <TransitionGroup component="tbody">
                                    {rowsPeriodic}
                                </TransitionGroup>
                            </table>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <h2 className="text-center">Pontuais</h2>
                        <div className="general__table">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Motivo</th>
                                        <th>Data Início</th>
                                        <th>Data Fim</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <TransitionGroup component="tbody">
                                    {rowsPonctual}
                                </TransitionGroup>
                            </table>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        )
    }
    deleteRow(e) {
        const id = e.target.parentNode.parentNode.parentNode.dataset.id;
        var positionInArray;
        isLoading('block');
        Axios.delete(
            'realtor-restriction/' + id,
        ).then(response => {
            // const data = this.state.data;
            // data.forEach(function (row, i) {
            //     //pega a posicao que tem que deletar
            //     if (row.ID == id) positionInArray = i;

            // })
            // data[positionInArray] = null;
            // const newData = data.filter(function (e) {
            //     return e;
            // });
            // console.log('====================================');
            // console.log(data);
            // console.log(newData);
            // console.log('====================================');
            // this.setState({
            //     data: newData
            // });
            this.handleGetInfo();
            isLoading('none');
        }).catch(error => {
            toastInfo('error', 'custom')
            console.log("erro", error, error.response);
            isLoading('none');
        });
    }
}

export default RealtorRestriction;