import React, { useState, useEffect } from 'react';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Chip from '@material-ui/core/Chip';


import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { useToast } from '../../../util/ToastProvider'
import axios from 'axios';

import {
	compareRealtor,
	convertDateISOToMaterialUIFormatDateTime,
	convertDateISOToMaterialUIFormatTime,
	convertWeekdayStringToNumber,
	convertTimeMaterialToGo,
	convertDateTimeMaterialToGoRealtorRestriction
} from '../../../util/helpers'

const RealtorEditPage = (props) => {
	const { handleOpenToast } = useToast();

	const [typeForm, setTypeForm] = useState(['Novo usuário']);

	const [typeRestriction, setTypeRestriction] = useState('punctual');
	const [realtor, setRealtor] = useState(1);
	
	const [allRealtors, setAllRealtors] = useState([]);
	const [weekday, setWeekday] = useState(0);
	const [weekdaySelected, setWeekdaySelected] = useState([]);

	const [allEvent, setAllEvent] = useState([]);
	const [eventsSelected, setEventsSelected] = useState(null);

	let formSchema = yup.object().shape({
		Reason: yup.string().required('Preencha o motivo'),
		StartAt: yup.string().required('Preencha o início'),
		EndAt: yup.string().required('Preencha o final'),
	});

	const { control, handleSubmit, errors, setValue } = useForm({
		validationSchema: formSchema
	});


	useEffect(() => {
		axios.get(`realtor?page=0&limit=10000&order=id asc`,
			).then(response => {
				var sortedList = Object.assign(response.data.List);
				sortedList = sortedList.filter(realtor => !realtor.Name.toUpperCase().includes("ADAC")).sort(compareRealtor);
				setAllRealtors(sortedList);
			}).catch(error => {
				handleOpenToast('error');
				console.log("erro", error);
			});
	}, [])
	useEffect(() => {
		axios.get(`event?page=0&limit=10000&order=id asc`,
		).then(response => {
			var sortedList = Object.assign(response.data);
			setAllEvent(sortedList);
		}).catch(error => {
			handleOpenToast('error');
			console.log("erro", error);
		});
	}, [])
	useEffect(() => {
		if (props.id >= 0) {
			setTypeForm('Editar usuário');
			axios.get(
				'realtor-restriction/' + props.id,
			).then(response => {
				let allData = response.data || [];
				setValue([
					{ Reason: allData.Reason },
				]);
				setRealtor(allData.RealtorID);
				setEventsSelected(allData.EventID);
				//se tiver o dia marcado e uma restricao semanal;
                if (allData.Weekday != null) {
					setTypeRestriction('periodic_day');
					setValue([
						{ StartAt: convertDateISOToMaterialUIFormatTime(allData.StartAt) },
						{ EndAt: convertDateISOToMaterialUIFormatTime(allData.EndAt) },
					]);
					setWeekday(allData.Weekday);
				} else {
					setValue([
						{ StartAt: convertDateISOToMaterialUIFormatDateTime(allData.StartAt) },
						{ EndAt: convertDateISOToMaterialUIFormatDateTime(allData.EndAt) },
					]);
				}
			}).catch(error => {
				handleOpenToast('error');
				console.log("erro", error);
			});
		}
	}, [])

	const onSubmit = (values) => {
		let objInputs = {
			RealtorID: Number(realtor),
			StartAt: values.StartAt,
			EndAt: values.EndAt,
			Reason: values.Reason,
			Weekday: null,
			EventID: eventsSelected
		};
		// eventsSelected.map(el => {
		// 	objInputs.EventID.push(allEvent[el].ID);
		// });
		// console.log('====================================');
		// console.log(objInputs);
		// console.log('====================================');
		// return;
		if (typeRestriction == 'periodic_day') {
			objInputs.Weekday = Number(weekday);
			objInputs.StartAt = convertTimeMaterialToGo(values.StartAt);
			objInputs.EndAt = convertTimeMaterialToGo(values.EndAt);
		} else {
			objInputs.StartAt = convertDateTimeMaterialToGoRealtorRestriction(values.StartAt);
			objInputs.EndAt = convertDateTimeMaterialToGoRealtorRestriction(values.EndAt);
		}
		
		if(typeRestriction == 'periodic_day' && props.id === 'new') {
			let weekdaysSel = convertWeekdayStringToNumber(weekdaySelected);
			weekdaysSel.map(el => {
				axios.post(
					'realtor-restriction',
					{
                        Reason: values.Reason,
						StartAt: convertTimeMaterialToGo(values.StartAt),
						EndAt: convertTimeMaterialToGo(values.EndAt),
						RealtorID: Number(realtor),
						Weekday: Number(el)
                    },
				).then(response => {
					handleOpenToast('create');
				}).catch(error => {
					handleOpenToast('error');
					console.log("erro", error);
				});
			});
		} else {
			if(props.id === 'new') {
				axios.post(
					'realtor-restriction',
					objInputs,
				).then(response => {
					handleOpenToast('create');
				}).catch(error => {
					handleOpenToast('error');
					console.log("erro", error);
				});
			}
			if(props.id >= 0 ) {
				objInputs.ID = Number(props.id);
				axios.patch(
					'realtor-restriction',
					objInputs,
				).then(response => {
					handleOpenToast('update');
				}).catch(error => {
					handleOpenToast('error');
					console.log("erro", error);
				});
			}
		}
	}

	const pickerDatetime = <>
								<Grid item xs={12} sm={6}>
									<Controller
										as={TextField}
										control={control}
										type="datetime-local"
										name="StartAt"
										label="Início"
										margin="normal"
										variant="outlined"
										defaultValue=""
										max="1979-12-31"
										fullWidth
										error={errors.StartAt ? true : false}
										helperText={errors.StartAt && errors.StartAt.message}
										InputProps={{inputProps: { min: "2020-12-01T10:30", max: "2100-01-01T10:30"} }}
										InputLabelProps={{
											shrink: true,
										}}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Controller
										as={TextField}
										control={control}
										type="datetime-local"
										name="EndAt"
										label="Final"
										margin="normal"
										variant="outlined"
										defaultValue=""
										fullWidth
										error={errors.EndAt ? true : false}
										helperText={errors.EndAt && errors.EndAt.message}
										InputProps={{inputProps: { min: "2020-12-01T10:30", max: "2100-01-01T10:30"} }}
										InputLabelProps={{
											shrink: true,
										}}
									/>
								</Grid>
							</>;
	const pickerTime = <>
							<Grid item xs={12} sm={6}>
								<Controller
									as={TextField}
									control={control}
									type="time"
									name="StartAt"
									label="Horário Inicial"
									margin="normal"
									variant="outlined"
									defaultValue=""
									fullWidth
									error={errors.StartAt ? true : false}
									helperText={errors.StartAt && errors.StartAt.message}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Controller
									as={TextField}
									control={control}
									type="time"
									name="EndAt"
									label="Horário Final"
									margin="normal"
									variant="outlined"
									defaultValue=""
									fullWidth
									error={errors.EndAt ? true : false}
									helperText={errors.EndAt && errors.EndAt.message}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</Grid>
						</>;

	const periodicNew = <Grid item xs={12} sm={6}>
							<FormControl variant="outlined" fullWidth margin="normal">
								<InputLabel>Dias da Semana</InputLabel>
								<Controller
									as={(props) =>
										<Select
											variant="outlined"
											fullWidth
											labelId="weekdays-label"
											label="Dias da Semana"
											id="weekdays"
											multiple
											value={weekdaySelected}
											onChange={(e) => setWeekdaySelected(e.target.value)}
											renderValue={(selected) => (
												<div>
													{selected.map((el) => (
														<Chip key={el} label={el} />
													))}
												</div>
											)}
										>
											<MenuItem key="0" value="Domingo">Domingo</MenuItem>
											<MenuItem key="1" value="Segunda-Feira">Segunda-Feira</MenuItem>
											<MenuItem key="2" value="Terça-Feira">Terça-Feira</MenuItem>
											<MenuItem key="3" value="Quarta-Feira">Quarta-Feira</MenuItem>
											<MenuItem key="4" value="Quinta-Feira">Quinta-Feira</MenuItem>
											<MenuItem key="5" value="Sexta-Feira">Sexta-Feira</MenuItem>
											<MenuItem key="6" value="Sábado">Sábado</MenuItem>
										</Select>
									}

									control={control}
									defaultValue=""
									name="Weekdays"
								>
								</Controller>
							</FormControl>
						</Grid>;
	const periodicEdit = <Grid item xs={12} sm={6}>
							<FormControl variant="outlined" fullWidth margin="normal">
								<InputLabel>Tipo de Restrição</InputLabel>
								<Controller
									as={(props) =>
										<Select
											onChange={(el => setWeekday(el.target.value))}
											name="weekday"
											label="Dia da semana"
											control={control}
											value={weekday}
										>
											<MenuItem value={'0'} key={2}>Domingo</MenuItem>
											<MenuItem value={'1'} key={2}>Segunda-Feira</MenuItem>
											<MenuItem value={'2'} key={2}>Terça-Feira</MenuItem>
											<MenuItem value={'3'} key={2}>Quarta-Feira</MenuItem>
											<MenuItem value={'4'} key={2}>Quinta-Feira</MenuItem>
											<MenuItem value={'5'} key={2}>Sexta-Feira</MenuItem>
											<MenuItem value={'6'} key={2}>Sábado</MenuItem>
										</Select>
									}

									control={control}
									defaultValue=""
									name="weekday"
								>
								</Controller>
							</FormControl>
						</Grid>;


	// const eventsPickerNew = <Grid item xs={12}>
	// 	<FormControl variant="outlined" fullWidth margin="normal">
	// 		<InputLabel>Evento</InputLabel>
	// 		<Controller
	// 			as={(props) =>
	// 				<Select
	// 					variant="outlined"
	// 					fullWidth
	// 					label="Eventos"
	// 					id="events"
	// 					multiple
	// 					value={eventsSelected}
	// 					onChange={(e) => setEventsSelected(e.target.value)}
	// 					renderValue={(selected) => (
	// 						<div>
	// 							{selected.map((el) => (
	// 								<Chip key={el} label={allEvent[el].Description} />
	// 							))}
	// 						</div>
	// 					)}
	// 				>
	// 					{allEvent.map((el, i) => (
	// 						<MenuItem value={i} key={i}>{el.Description}</MenuItem>
	// 					))}
	// 				</Select>
	// 			}

	// 			control={control}
	// 			defaultValue=""
	// 			name="Events"
	// 		>
	// 		</Controller>
	// 	</FormControl>
	// </Grid>;
	const eventsPickerEdit = <Grid item xs={12}>
		<FormControl variant="outlined" fullWidth margin="normal">
			<InputLabel>Evento a parte (Não será incluído na restrição)</InputLabel>
			<Controller
				as={(props) =>
					<Select
						variant="outlined"
						fullWidth
						label="Evento a parte (Não será incluído na restrição)"
						id="events"
						value={eventsSelected}
						onChange={(e) => setEventsSelected(e.target.value)}
					>
						<MenuItem value={null}>Nenhum</MenuItem>
						{allEvent.map((el, i) => (
							<MenuItem value={el.ID} key={i}>{el.Description}</MenuItem>
						))}
					</Select>
				}

				control={control}
				defaultValue=""
				name="Events"
			>
			</Controller>
		</FormControl>
	</Grid>;
	return (
		<Container maxWidth="lg">
			<Paper className="paper" className="form-container">
				<Typography variant="h6">
					Informações Primárias
					</Typography>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Grid container spacing={1}>
						<Grid item xs={12} sm={6}>
							<FormControl variant="outlined" fullWidth margin="normal">
								<InputLabel>Tipo de Restrição</InputLabel>
								<Controller
									as={(props) =>
										<Select
											onChange={(el => setTypeRestriction(el.target.value))}
											name="RestrictionType"
											label="Tipo de Restrição"
											control={control}
											value={typeRestriction}
										>
											<MenuItem value={'punctual'} key={1}>Pontual</MenuItem>
											<MenuItem value={'periodic_day'} key={2}>Semanal</MenuItem>
										</Select>
									}

									control={control}
									defaultValue=""
									name="RestrictionType"
								>
								</Controller>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={6}>
							<FormControl variant="outlined" fullWidth margin="normal">
								<InputLabel>Corretor</InputLabel>
								<Controller
									as={(props) =>
										<Select
											onChange={(el => setRealtor(el.target.value))}
											name="RealtorID"
											defaultValue=""
											label="Tipo de Restrição"
											control={control}
											value={realtor}
										>
											{allRealtors.map((el, i) => (
												<MenuItem value={el.ID} key={i}>{el.Name}</MenuItem>
											))}
										</Select>
									}

									control={control}
									defaultValue=""
									name="RealtorID"
								>
								</Controller>
							</FormControl>
						</Grid>

						<Grid item xs={12}>
							<Controller
								as={TextField}
								name="Reason"
								control={control}
								label="Motivo"
								margin="normal"
								variant="outlined"
								defaultValue=""
								fullWidth
								error={errors.Reason ? true : false}
								helperText={errors.Reason && errors.Reason.message}
							/>
						</Grid>
						
						{typeRestriction == 'periodic_day' ? pickerTime : pickerDatetime}

						{/* se for periodico, e for um registro novo vai mostrar a opcao de selecao de varios se nao edicao solitaria */}
						{typeRestriction == 'periodic_day' ? (props.id >= 0 ? periodicEdit : periodicNew) : ''}
						{/* {props.id >= 0 ? eventsPickerEdit : eventsPickerNew} */}
						{eventsPickerEdit}

						<Grid item xs={12}>
							<Button type="submit" variant="outlined" aria-label="delete" color="primary" >
								Salvar
							</Button>
						</Grid>

					</Grid>
				</form>
			</Paper>
		</Container>
	);
};

export default RealtorEditPage;