import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { useToast } from '../../../util/ToastProvider'
import axios from 'axios';

const EventEditPage = (props) => {
	const { handleOpenToast } = useToast();

	// const [typeForm, setTypeForm] = useState(['Novo usuário']);

	let formSchema = yup.object().shape({
		Name: yup.string().required('Preencha o nome'),
		Description: yup.string().required('Preencha o descrição'),
		Color: yup.string().required('Preencha o cor'),
		Priority: yup.string().required('Preencha a prioridade'),
	});

	const { control, handleSubmit, errors, setValue } = useForm({
		validationSchema: formSchema
	});


	useEffect(() => {
		if (props.id >= 0) {
			axios.get(
				'event/' + props.id,
			).then(response => {
				let allData = response.data || [];
				setValue([
					{ Name: allData.Name },
					{ Description: allData.Description },
					{ Color: allData.Color },
					{ Priority: allData.Priority },
				]);
			}).catch(error => {
				handleOpenToast('error');
				console.log("erro", error);
			});
		}
	}, [])

	const onSubmit = (values) => {
		let objInputs = {
			Name: values.Name,
			Description: values.Description,
			Color: values.Color,
			Priority: Number(values.Priority),
		};
		if (values.Password) objInputs.Password = values.Password;
		if (props.id >= 0) {
			objInputs.ID = Number(props.id);
			axios.patch(
				'event',
				objInputs,
			).then(response => {
				handleOpenToast('update');
			}).catch(error => {
				handleOpenToast('error');
				console.log("erro", error);
			});
		} else {
			axios.post(
				'event',
				objInputs,
			).then(response => {
				handleOpenToast('create');
			}).catch(error => {
				handleOpenToast('error');
				console.log("erro", error);
			});
		}
	}
	return (
		<Container maxWidth="lg">
			<Paper className="paper" className="form-container">
				<Typography variant="h6">
					Informações Primárias
					</Typography>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Grid container spacing={1}>
						<Grid item xs={12} sm={6}>
							<Controller
								as={TextField}
								name="Name"
								control={control}
								label="Nome"
								margin="normal"
								variant="outlined"
								defaultValue=""
								fullWidth
								error={errors.Name ? true : false}
								helperText={errors.Name && errors.Name.message}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Controller
								as={TextField}
								name="Description"
								control={control}
								label="Descrição"
								margin="normal"
								variant="outlined"
								defaultValue=""
								fullWidth
								error={errors.Description ? true : false}
								helperText={errors.Description && errors.Description.message}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Controller
								as={TextField}
								name="Priority"
								control={control}
								label="Prioridade"
								type="number"
								margin="normal"
								variant="outlined"
								defaultValue=""
								fullWidth
								error={errors.Priority ? true : false}
								helperText={errors.Priority && errors.Priority.message}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Controller
								as={TextField}
								name="Color"
								control={control}
								label="Cor"
								type="color"
								margin="normal"
								variant="outlined"
								defaultValue=""
								fullWidth
								error={errors.Color ? true : false}
								helperText={errors.Color && errors.Color.message}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Button type="submit" variant="outlined" aria-label="delete" color="primary" >Continuar</Button>
						</Grid>
					</Grid>
				</form>
			</Paper>
		</Container>
	);
};

export default EventEditPage;