import React, { Component } from 'react';
import {
	titlePage,
	toastInfo,
	isLoading,
	convertDateISOToBROnlyHour,
	convertDateISOToBROnlyDate,
	numberDayToString,
} from '../../../util/helpers';
import MUIDataTable from "mui-datatables";
import Axios from 'axios';
import { SelectGroup } from './selectGroup';

require('@fullcalendar/core/main.min.css');
require('@fullcalendar/timegrid/main.css');
require('@fullcalendar/daygrid/main.css');
require('@fullcalendar/timeline/main.css');
require('@fullcalendar/resource-timeline/main.css');
// var Tooltip = require('Tooltip');
class DashboardPublicPage extends Component {
	constructor(props) {
		super(props)
		isLoading('block');
		this.handleGetAllDiaries = this.handleGetAllDiaries.bind(this);
		this.filterBySelect = this.filterBySelect.bind(this);
		this.limitPage = 1000;
		this.idSortAsc = true;
		this.state = {
			calendarWeekends: true,
			local: [],
			events: [],
			filters: {
				data: {
					name: 'data',
					value: 'Todos'
				},
				corretor: {
					name: 'corretor',
					value: 'Todos'
				},
				local: {
					name: 'local',
					value: 'Todos'
				},
			}
		}
	} 

	componentWillMount() {
		this.handleGetAllDiaries();
	}
	
	handleGetAllDiaries() {
		Axios.get(
			`http://plantao.bortoliniimoveis.com.br/api/app/diary/all`,
		{ 
			headers: {'Authorization': 'Bearer ' + this.props.match.params.token}
		}
		).then(response => {
			let realData = response.data || [];
			realData.map((el, i) => {
				realData[i].id = el.ID;
				realData[i].resourceId = el.Schedule.EventID;
				realData[i].start = convertDateISOToBROnlyHour(el.StartAt);
				realData[i].end = convertDateISOToBROnlyHour(el.EndAt);				
				realData[i].title = el.Realtor.Name;
				realData[i].locale = el.Schedule.Name;
				realData[i].date = convertDateISOToBROnlyDate(el.StartAt);
				realData[i].weekday = numberDayToString(el.Schedule.Weekday);
			});
			this.setState({
				data: realData,
			});
		}).catch(error => {
			console.log("erro", error, error.response);
			isLoading('none');
		});
	}

	componentDidMount() {
		// titlePage('Dashboard');
	}

	changePage = (page, itemToSort, isSearch = false) => {
		
		let typeToSort = 'asc';
		
		//se for sortado ja e nao e troca de pagina
		if(this.lastSorted && itemToSort != 'isChangePage') {
				//se o item e o id
			if(this.lastSorted == 'id') {
				//pega qual o tipo do sort
				if(this.idSortAsc) {
					typeToSort = 'desc';
					this.typeSortId = 'desc';
					this.idSortAsc = false;
				} else {
					typeToSort = 'asc';
					this.typeSortId = 'asc';
					this.idSortAsc = true;
				}
			}
			if(this.lastSorted == 'name') {
				if(this.descriptionSortAsc) {
					typeToSort = 'desc';
					this.typeSortDescription = 'desc';
					this.descriptionSortAsc = false;
				} else {
					typeToSort = 'asc';
					this.typeSortDescription = 'asc';
					this.descriptionSortAsc = true;
				}
			}
		}
		//se for sortado ja e nao e é troca de pagina
		if(itemToSort == 'isChangePage' && this.lastSorted) {
			if(this.lastSorted == 'id') typeToSort = this.typeSortId;
			if(this.lastSorted == 'description') typeToSort = this.typeSortDescription;
		}
		//se for somente troca de pagina nada te sido sortado
		if(itemToSort == 'isChangePage' && !this.lastSorted) {
			typeToSort = 'asc';
		}

		//se nao for pequisado pela barra
		if(!isSearch) {
			this.isSearch = false;
		}
		//se for pequisado pela barra
		if(isSearch) {
			this.isSearch = isSearch;
		}

		Axios.get(
			`http://api.plantao.bortoliniimoveis.com.br/app/diary/all`,
		{ 
			headers: {'Authorization': 'Bearer ' + this.props.match.params.token}
		}
		).then(response => {
			let realData = response.data || [];
			realData.map((el, i) => {
				realData[i].id = el.ID;
				realData[i].resourceId = el.Schedule.EventID;
				realData[i].start = convertDateISOToBROnlyHour(el.StartAt);
				realData[i].end = convertDateISOToBROnlyHour(el.EndAt);				
				realData[i].title = el.Realtor.Name;
				realData[i].locale = el.Schedule.Name;
				realData[i].date = convertDateISOToBROnlyDate(el.StartAt);
				realData[i].weekday = numberDayToString(el.Schedule.Weekday);
			});
			this.setState({
				data: realData,
				page: page,    
				count: realData.Count
			});
			isLoading('none');
		}).catch(error => {
			toastInfo('error', 'custom')
			console.log("erro", error, error.response);
			isLoading('none');
		});
	};

	filterBySelect(item) {
		if(this.state.filters.data.value != 'Todos') {
			if(this.state.filters.data.value != item.date) return false;
		}
		if(this.state.filters.corretor.value != 'Todos') {
			if(this.state.filters.corretor.value != item.Realtor.Name) return false;
		}
		if(this.state.filters.local.value != 'Todos') {
			if(this.state.filters.local.value != item.Schedule.Event.Name) return false;
		}
		return true;
	}

	render() {
		const { data, page, count } = this.state;
		let dataTable = data || [];
		let filteredData = dataTable.filter(item => this.filterBySelect(item));
console.log('====================================');
console.log(filteredData);
console.log('====================================');
		const columns = [
				// {
				// 	name: "id",
				// 	label: "ID",
				// 	options: {
				// 		filter: false,
				// 		sort: true,
				// 	}
				// },
				{
					name: "date",
					label: "Data",
					options: {
						filter: false,
						sort: false,
					}
				},
				{
					name: "weekday",
					label: "Dia da semana",
					options: {
						filter: false,
						sort: false,
					}
				},
				{
					name: "start",
					label: "Início",
					options: {
						filter: false,
						sort: false,
					}
				},
				{
					name: "end",
					label: "Fim",
					options: {
						filter: false,
						sort: false,
					}
				},
				{
					name: "title",
					label: "Corretor",
					options: {
						filter: false,
						sort: false,
					}
				},
				{
					name: "locale",
					label: "Local",
					options: {
						filter: false,
						sort: false,
					}
				},
				// {
				// 	name: "Schedule.Event.Color",
				// 	label: "Cor corresp. Local",
				// 	options: {
				// 		filter: false,
				// 		sort: false,
				// 		customBodyRender: (value) => {
				// 			return (
				// 				<div style={{width: '100%', height: '25px', backgroundColor: `${value}`}}></div>
				// 			);
				// 		}
				// 	}
				// }				
		];
		
		const options = {
			filterType: 'checkbox',
			filter: false,
			print: false,
			search: false,
			searchText: this.isSearch,
			download: false,
			rowsPerPage: this.limitPage,
			rowsPerPageOptions: [],
			selectableRows: 'none',
			selectableRowsOnClick: true,
			serverSide: true,
			count: count,
			page: page,
			responsive: "standard",
			onColumnSortChange: (changedColumn, direction) => {
					this.lastSorted = changedColumn.toLowerCase();
					this.changePage(this.actualPage, changedColumn.toLowerCase(), this.isSearch);
			},
			
			onTableChange: (action, tableState) => {
				this.actualPage = tableState.page;

				switch (action) {
					case 'changePage':
						this.changePage(tableState.page, 'itemToSort', this.isSearch);
						break;
				}
			},
			
			textLabels: {
				body: {
					noMatch: "Desculpe, nenhum registro encontrado.",
					toolTip: "Ordenar",
					columnHeaderTooltip: column => `Ordernar por ${column.label}`
				},
				pagination: {
					next: "Proxima página",
					previous: "Página Anterior",
					rowsPerPage: "Linhas por página:",
					displayRows: "de",
				},
				toolbar: {					
					downloadCsv: "Download CSV",
					print: "Imprimir",
					viewColumns: "Ver colunas",
					filterTable: "Filtrar tabela",
				},
				filter: {
					all: "Tudo",
					title: "FILTERS",
					reset: "RESET",
				}
			}
		};		

		const changeSelect = (type, selectFilter) => {									
			let stateToHandle = this.state.filters;
			stateToHandle[type] = selectFilter;
			this.setState({
				filters: stateToHandle
			});
		}

		return (
			<div className="demo-app dashboard dashboard__public">
				<SelectGroup dados={dataTable} changeSelect={changeSelect}/>
				<MUIDataTable
					title={""}
					data={filteredData}
					columns={columns}
					options={options}
				/>
			</div>
		)
	}
}

export default DashboardPublicPage;