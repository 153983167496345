import React, { useState, useContext, useCallback, createContext } from "react";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';


// use
{/* <a onClick={()=>handleOpenToast('Sucess', 'success', 2000, true)}>Succes</a><br/> */}
{/* <a onClick={()=>handleOpenToast('Informativo', 'info', 2000, true)}>Info</a><br/> */}
{/* <a onClick={()=>handleOpenToast('Error', 'error', 2000, true)}>Error</a> */}


const ToastContext = createContext(null);

const ToastProvider = ({ children }) => {
  const [openToast, setOpenToast] = useState(false);
  const [infoToast, setInfoToast] = useState({
    open: false,
    text: '',
    severity: 'info',
    duration: 2000
});

  const handleOpenToast = useCallback(
    (text, severity = 'info', duration = 2000, type = true, description) => {
      if(text === 'create') {
        severity = 'success';
        text = 'Criado com sucesso!';
      }
      if(text === 'update') {
        severity = 'success';
        text = 'Atualizado com sucesso!';
      }
      if(text === 'error') {
        severity = 'error';
        text = 'Erro. Tente novamente!';
      }
        setInfoToast({
            text: text,
            severity: severity,
            duration: duration,
        });
        setOpenToast(type);
    },
    [setInfoToast, setOpenToast]
  );

 const handleCloseToast = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenToast(false);
    }

  return (
    <ToastContext.Provider
      value={{
        handleOpenToast,
        handleCloseToast
      }}
    >
      <Snackbar open={openToast} autoHideDuration={infoToast.duration} onClose={handleCloseToast}>
        <MuiAlert onClose={handleCloseToast} severity={infoToast.severity}>
          {infoToast.text}
        </MuiAlert>
      </Snackbar>
      {children}
    </ToastContext.Provider>
  );
};

const useToast = () => {
  const toastHelpers = useContext(ToastContext);

  return toastHelpers;
};

export { ToastContext, useToast };
export default ToastProvider;
