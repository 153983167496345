import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Chip from '@material-ui/core/Chip';


import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { useToast } from '../../../util/ToastProvider'
import axios from 'axios';

import {
	compareRealtor,
	convertDateISOToMaterialUIFormatDateTime,
	convertDateTimeUnionMaterialToGo,
	convertDateISOToMaterialUIFormatTime,
	convertWeekdayStringToNumber,
	convertTimeMaterialToGo,
	convertDateTimeMaterialToGo
} from '../../../util/helpers'

const RealtorEditPage = (props) => {
	const { handleOpenToast } = useToast();

	const [typeForm, setTypeForm] = useState(['Novo usuário']);

	const [realtor, setRealtor] = useState([]);
	const [schedule, setSchedule] = useState([]);

	const [allRealtors, setAllRealtors] = useState([]);
	const [allSchedules, setAllSchedules] = useState([]);
	const [realtorsSelected, setRealtorsSelected] = useState([]);
	const [realtorsIDSelected, setRealtorsIDSelected] = useState([]);

	let formSchema = yup.object().shape({
		StartAt: yup.string().required('Preencha o início'),
		EndAt: yup.string().required('Preencha o final'),
	});

	const { control, handleSubmit, errors, setValue } = useForm({
		validationSchema: formSchema
	});


	useEffect(() => {
		axios.get(`realtor?page=0&limit=10000&order=id asc`,
		).then(response => {
			var sortedList = Object.assign(response.data.List);
			// sortedList = sortedList.filter(realtor => !realtor.Name.toUpperCase().includes("ADAC")).sort(compareRealtor);
			setAllRealtors(sortedList);
		}).catch(error => {
			handleOpenToast('error');
			console.log("erro", error);
		});
	}, [])
	useEffect(() => {
		if (props.id >= 0) {
			setTypeForm('Editar usuário');
			axios.get(
				'diary/' + props.id,
			).then(response => {
				let allData = response.data || [];
				setRealtor(allData.RealtorID);
				setSchedule(allData.ScheduleID);
				setValue([
					{ StartAt: convertDateISOToMaterialUIFormatDateTime(allData.StartAt) },
					{ EndAt: convertDateISOToMaterialUIFormatDateTime(allData.EndAt) },
				]);
			}).catch(error => {
				handleOpenToast('error');
				console.log("erro", error);
			});
		}
	}, [])

	useEffect(() => {
		axios.get(`schedule?page=0&limit=10000&order=id asc`,
		).then(response => {
			var allData = response.data || [];
			setAllSchedules(allData);
		}).catch(error => {
			handleOpenToast('error');
			console.log("erro", error);
		});
	}, [])

	const onSubmit = (values) => {
		let objInputs = {
			ScheduleID: Number(schedule),
			StartAt: convertDateTimeUnionMaterialToGo(values.StartAt),
			EndAt: convertDateTimeUnionMaterialToGo(values.EndAt),
		};

		if (props.id === 'new') {
			realtorsSelected.map(el => {
				axios.post(
					'diary',
					{
						RealtorID: Number(allRealtors[el].ID),
						ScheduleID: Number(schedule),
						StartAt: convertDateTimeUnionMaterialToGo(values.StartAt),
						EndAt: convertDateTimeUnionMaterialToGo(values.EndAt),
					},
				).then(response => {
					handleOpenToast('create');
				}).catch(error => {
					handleOpenToast('error');
					console.log("erro", error);
				});
			});
		}
		if (props.id >= 0) {
			objInputs.ID = Number(props.id);
			objInputs.RealtorID = Number(realtor);
			axios.patch(
				'diary',
				objInputs,
			).then(response => {
				handleOpenToast('update');
			}).catch(error => {
				handleOpenToast('error');
				console.log("erro", error);
			});
		}
	}


	const realtorsPicker = <Grid item xs={12}>
		<FormControl variant="outlined" fullWidth margin="normal">
			<InputLabel>Corretores</InputLabel>
			<Controller
				as={(props) =>
					<Select
						variant="outlined"
						fullWidth
						label="Corretores"
						id="realtors"
						multiple
						value={realtorsSelected}
						onChange={(e) => setRealtorsSelected(e.target.value)
						}
						renderValue={(selected) => (
							<div>
								{selected.map((el) => (
									<Chip key={el} label={allRealtors[el].Name} />
								))}
							</div>
						)}
					>
						{allRealtors.map((el, i) => (
							<MenuItem value={i} key={i}>{el.Name}</MenuItem>
						))}
					</Select>
				}

				control={control}
				defaultValue=""
				name="Weekdays"
			>
			</Controller>
		</FormControl>
	</Grid>;

	const realtorPicker = <Grid item xs={12} sm={6}>
		<FormControl variant="outlined" fullWidth margin="normal">
			<InputLabel>Corretor</InputLabel>
			<Controller
				as={(props) =>
					<Select
						onChange={(el => setRealtor(el.target.value))}
						name="RealtorID"
						defaultValue=""
						label="Tipo de Restrição"
						control={control}
						value={realtor}
					>
						{allRealtors.map((el, i) => (
							<MenuItem value={el.ID} key={i}>{el.Name}</MenuItem>
						))}
					</Select>
				}

				control={control}
				defaultValue=""
				name="RealtorID"
			>
			</Controller>
		</FormControl>
	</Grid>;

	return (
		<Container maxWidth="lg">
			<Paper className="paper" className="form-container">
				<Typography variant="h6">
					Informações Primárias
				</Typography>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Grid container spacing={1}>
						<Grid item xs={12}>
							<FormControl variant="outlined" fullWidth margin="normal">
								<InputLabel>Cronogramas</InputLabel>
								<Controller
									as={(props) =>
										<Select
											onChange={(el => setSchedule(el.target.value))}
											name="ScheduleID"
											label="Tipo de Restrição"
											control={control}
											value={schedule}
										>
											{allSchedules.map((el, i) => (
												<MenuItem value={el.ID} key={i}>{el.Name}</MenuItem>
											))}
										</Select>
									}

									control={control}
									defaultValue=""
									name="ScheduleID"
								>
								</Controller>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Controller
								as={TextField}
								control={control}
								type="datetime-local"
								name="StartAt"
								label="Início"
								margin="normal"
								variant="outlined"
								defaultValue=""
								fullWidth
								error={errors.StartAt ? true : false}
								helperText={errors.StartAt && errors.StartAt.message}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Controller
								as={TextField}
								control={control}
								type="datetime-local"
								name="EndAt"
								label="Final"
								margin="normal"
								variant="outlined"
								defaultValue=""
								fullWidth
								error={errors.EndAt ? true : false}
								helperText={errors.EndAt && errors.EndAt.message}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
						
						{props.id >= 0 ? realtorPicker : realtorsPicker}

						<Grid item xs={12}>
							<Button type="submit" variant="outlined" aria-label="delete" color="primary" >
								Salvar
							</Button>
						</Grid>
					</Grid>
				</form>
			</Paper>
		</Container>
	);
};

export default RealtorEditPage;