import React from 'react';
import TableAdac from '../../../util/TableAdac';

const RealtorListPage = () => {

    return (
		<TableAdac
			urlApi="realtor"
			urlFrontend="corretor"
			pagination={true}
			columnsArr={[
				{
					name: "ID",
					label: "ID",
					options: {
						filter: true,
						sort: true,
					}
				},
				{
					name: "Name",
					label: "Nome",
					options: {
						filter: true,
						sort: true,
					}
				},
			]}
		/>
	);
};

export default RealtorListPage;