import React from 'react';
import { useParams } from 'react-router-dom';

import RealtorListPage from './RealtorList';
import RealtorEditPage from './RealtorEdit';

const RealtorPage = () => {
	let params = useParams();
	if(params.edit === 'edit') {
		return <RealtorEditPage id={params.id} />
	} else {
		return <RealtorListPage />	
	}
};

export default RealtorPage;