import React from 'react';
import { useParams } from 'react-router-dom';

import DiariesListPage from './DiariesList';
import DiariesEditPage from './DiariesEdit';

const DiariesPage = () => {
	let params = useParams();
	if(params.edit === 'edit') {
		return <DiariesEditPage id={params.id} />
	} else {
		return <DiariesListPage />	
	}
};

export default DiariesPage;