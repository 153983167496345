import React, { useEffect, useState } from 'react';
import axios from 'axios';
import TableAdac from '../../../util/TableAdac';
import { convertDateISOToMaterialUIFormatTime, numberDayToString, convertDateISOToBROnlyDate } from '../../../util/helpers';

const RealtorRestrictionListPage = () => {
	const [realtorsWithRestriction, setRealtorsWithRestricion] = useState([]);

	useEffect(() => {
		axios.get(`realtor-with-restriction`,
		).then(response => {
			let data = response.data || [];
			let newData = [];
			data.map(el => {
				newData.push(el.ID + ' | ' + el.Name);
			})
			setRealtorsWithRestricion(newData);
		});
	}, []);

	return (
		<TableAdac
			urlApi="realtor-restriction"
			urlFrontend="restricao-do-corretor"
			pagination={true}
			filter={true}
			columnsArr={[
				{
					name: "ID",
					label: "ID",
					options: {
						filter: false,
						sort: true,
					}
				},
				{
					name: "Reason",
					label: "Motivo",
					options: {
						filter: false,
						sort: true,
					}
				},
				{
					name: "Realtor.Name",
					label: "Corretor",
					options: {
						filter: true,
						sort: true,
						// customFilterListOptions: { render: el => `Name: ${el}` },
						filterOptions: {
							names: realtorsWithRestriction
						},
					},

				},
				{
					name: "Weekday",
					label: "Dia",
					options: {
						filter: false,
						sort: true,
					}
				},
				{
					name: "StartAt",
					label: "Inicio",
					options: {
						filter: false,
						sort: true,
					}
				},
				{
					name: "EndAt",
					label: "Final",
					options: {
						filter: false,
						sort: true,
					}
				},
			]}
			funcsColumn={{
				Weekday: (el) => el.Weekday != null ? numberDayToString(el.Weekday) : convertDateISOToBROnlyDate(el.StartAt),
				StartAt: (el) => convertDateISOToMaterialUIFormatTime(el.StartAt),
				EndAt: (el) => convertDateISOToMaterialUIFormatTime(el.EndAt),
			}}
		/>
	);
};

export default RealtorRestrictionListPage;