import React from 'react';
import TableAdac from '../../../util/TableAdac';
import { convertDateISOToMaterialUIFormatTime, numberDayToString, convertDateISOToBROnlyDate } from '../../../util/helpers';

const GeneralRestrictionsListPage = () => {

    return (
		<TableAdac
			urlApi="restriction"
			urlFrontend="restricao-geral"
			columnsArr={[
				{
					name: "ID",
					label: "ID",
					options: {
						filter: true,
						sort: true,
					}
				},
				{
					name: "Name",
					label: "Nome",
					options: {
						filter: true,
						sort: true,
					}
				},
				{
					name: "Weekday",
					label: "Dia",
					options: {
						filter: true,
						sort: true,
					}
				},
				{
					name: "StartAt",
					label: "Inicio",
					options: {
						filter: true,
						sort: true,
					}
				},
				{
					name: "EndAt",
					label: "Final",
					options: {
						filter: true,
						sort: true,
					}
				},
			]}
			funcsColumn={{
				Weekday: (el) => el.Weekday != null ? numberDayToString(el.Weekday) : convertDateISOToBROnlyDate(el.StartAt),
				StartAt: (el) => convertDateISOToMaterialUIFormatTime(el.StartAt),
				EndAt: (el) => convertDateISOToMaterialUIFormatTime(el.EndAt),
			}}
		/>
	);
};

export default GeneralRestrictionsListPage;