// react
import React, { useState, useEffect } from 'react';
// others
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import axios from 'axios';
// @material-ui
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
// local files, functions
import { useToast } from '../../../util/ToastProvider'



const UserEditPage = (props) => {
	const { handleOpenToast } = useToast();

	const [typeForm, setTypeForm] = useState(['Novo usuário']);

	let formSchema = yup.object().shape({
        Name: yup.string().required('Preencha o campo'),
        Email: yup.string().email().required('Preencha o campo'),
    });

    const { control, handleSubmit, errors, setValue } = useForm({
        validationSchema: formSchema
	});
	

	useEffect(() => {
		if(props.id >= 0) {
			setTypeForm('Editar usuário');
			axios.get(
                'user/' + props.id,
            ).then(response => {
				let allData = response.data || [];
				setValue([
					{Name: allData.Name},
					{Email: allData.Email},
					{Type: allData.Type}
				]);
            }).catch(error => {
				handleOpenToast('error');
				console.log("erro", error);
            });
		}
	}, [])

	const onSubmit = (values) => {
		let objInputs = {
			Name:  values.Name,
			Email: values.Email,
			Type:  values.Type
		};
		if(values.Password) objInputs.Password = values.Password;
		if(props.id >= 0) {
            objInputs.ID = Number(props.id);
            axios.patch(
                'user',
                objInputs,
            ).then(response => {
                handleOpenToast('update');
            }).catch(error => {
                handleOpenToast('error');
				console.log("erro", error);
            });
		} else {
			axios.post(
				'user',
				objInputs,
			).then(response => {
				handleOpenToast('create');
			}).catch(error => {
				handleOpenToast('error');
				console.log("erro", error);
			});
		}
	}

    return (
		<Container maxWidth="lg">
			<Paper className="paper" className="form-container">
				<Typography variant="h4">
					{typeForm}
				</Typography>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Grid container spacing={3}>
						
							<Grid item xs={12} sm={6}>
								<Controller
									as={TextField}
									name="Name"
									control={control}
									label="Nome"
									margin="normal"
									variant="outlined"
									defaultValue=""
									fullWidth
									error={errors.Name ? true : false}
									helperText={errors.Name && errors.Name.message}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Controller
									as={TextField}
									name="Email"
									control={control}
									label="Email"
									margin="normal"
									variant="outlined"
									defaultValue=""
									fullWidth
									error={errors.Email ? true : false}
									helperText={errors.Email && errors.Email.message}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Controller
									as={TextField}
									name="Password"
									type="password"
									control={control}
									label="Password"
									margin="normal"
									variant="outlined"
									defaultValue=""
									fullWidth
									error={errors.Password ? true : false}
									helperText={errors.Password && errors.Password.message}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<FormControl variant="outlined" fullWidth margin="normal">
									<InputLabel>Tipo de Acesso</InputLabel>
										<Controller
											as={
												<Select>
													<MenuItem value={'administrator'}>Administrador</MenuItem>
													<MenuItem value={'receptionist'}>Recepcionista</MenuItem>
												</Select>
											}
										label="Tipo de Acesso"
										name="Type"
										control={control}
										defaultValue="administrator"
									/>
								</FormControl>
							</Grid>
							<Grid item xs={12}>
								<Button type="submit" variant="outlined" aria-label="delete" color="primary" >
									Continuar
								</Button>
							</Grid>
					</Grid>
				</form>
			</Paper>
		</Container>
	);
};

export default UserEditPage;