import React from 'react';
import TableAdac from '../../util/TableAdac';

const ReportListPage = () => {

    return (
		<TableAdac
			urlApi="reports"
            urlFrontend="relatorio"
            isReport={true}
			columnsArr={[
				{
					name: "ID",
					label: "ID",
					options: {
						filter: true,
						sort: true,
					}
				},
				{
					name: "Name",
					label: "Nome",
					options: {
						filter: true,
						sort: true,
					}
				},
            ]}
		/>
	);
};

export default ReportListPage;