import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Chip from '@material-ui/core/Chip';


import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { useToast } from '../../../util/ToastProvider'
import axios from 'axios';

import {
	compareRealtor,
	convertDateISOToMaterialUIFormatDateTime,
	convertDateISOToMaterialUIFormatDate,
	convertDateISOToMaterialUIFormatTime,
	convertWeekdayStringToNumber,
	convertTimeMaterialToGo,
	convertDateTimeMaterialToGo
} from '../../../util/helpers'

const ScheduleEditPage = (props) => {
	const { handleOpenToast } = useToast();

	const [typeForm, setTypeForm] = useState(['Novo usuário']);

	const [typeRestriction, setTypeRestriction] = useState('punctual');

	const [event, setEvent] = useState([]);
	const [allEvents, setAllEvents] = useState([]);
	
	const [weekday, setWeekday] = useState(0);
	const [weekdaySelected, setWeekdaySelected] = useState([]);

	let formSchema = yup.object().shape({
		// RequiredRealtor: yup.string().number('Preencha a quantidade re pessoas'),
		// Name: yup.string().required('Preencha o nome'),
	});

	const { control, handleSubmit, errors, setValue } = useForm({
		validationSchema: formSchema
	});


	useEffect(() => {
		axios.get(`event?page=0&limit=10000&order=id asc`,
			).then(response => {
				var allData = response.data || [];
				setAllEvents(allData);
			}).catch(error => {
				handleOpenToast('error');
				console.log("erro", error);
			});
	}, [])
	useEffect(() => {
		if (props.id >= 0) {
			setTypeForm('Editar usuário');
			axios.get(
				'schedule/' + props.id,
			).then(response => {
				let allData = response.data || [];
				setValue([
					{ Name: allData.Name },
					{ RequiredRealtor: allData.RequiredRealtor },
				]);
				setEvent(allData.EventID);
				
				//se tiver o dia marcado e uma restricao semanal;
                if (allData.Weekday != null) {
					setTypeRestriction('periodic_day');
					setValue([
						{ StartAt: convertDateISOToMaterialUIFormatTime(allData.StartAt) },
						{ EndAt: convertDateISOToMaterialUIFormatTime(allData.EndAt) },
					]);
					setWeekday(allData.Weekday);
				} else {
					setValue([
						{ DateOnly: convertDateISOToMaterialUIFormatDate(allData.StartAt) },
						{ StartAt: convertDateISOToMaterialUIFormatTime(allData.StartAt) },
						{ EndAt: convertDateISOToMaterialUIFormatTime(allData.EndAt) },
					]);
				}
			}).catch(error => {
				handleOpenToast('error');
				console.log("erro", error);
			});
		}
	}, [])

	const onSubmit = (values) => {
		let objInputs = {
			Name: values.Name,
			StartAt: values.StartAt,
			EndAt: values.EndAt,
			RequiredRealtor: Number(values.RequiredRealtor),
			Weekday: null,
			EventID: Number(event)
		};
		if (typeRestriction == 'periodic_day') {
			objInputs.Weekday = Number(weekday);
			objInputs.StartAt = convertTimeMaterialToGo(values.StartAt);
			objInputs.EndAt = convertTimeMaterialToGo(values.EndAt);
		} else {
			objInputs.StartAt = convertDateTimeMaterialToGo(values.DateOnly, values.StartAt);
			objInputs.EndAt = convertDateTimeMaterialToGo(values.DateOnly, values.EndAt);
		}
		if(typeRestriction == 'periodic_day' && props.id === 'new') {
			let weekdaysSel = convertWeekdayStringToNumber(weekdaySelected);
			weekdaysSel.map(el => {
				axios.post(
					'schedule',
					{
                        Name: values.Name,
                        RequiredRealtor: Number(values.RequiredRealtor),
						StartAt: convertTimeMaterialToGo(values.StartAt),
						EndAt: convertTimeMaterialToGo(values.EndAt),
						Weekday: Number(el),
						EventID: Number(event)
                    },
				).then(response => {
					handleOpenToast('create');
				}).catch(error => {
					handleOpenToast('error');
					console.log("erro", error);
				});
			});
		} else {
			if(props.id === 'new') {
				axios.post(
					'schedule',
					objInputs,
				).then(response => {
					handleOpenToast('create');
				}).catch(error => {
					handleOpenToast('error');
					console.log("erro", error);
				});
			}
			if(props.id >= 0 ) {
				objInputs.ID = Number(props.id);
				axios.patch(
					'schedule',
					objInputs,
				).then(response => {
					handleOpenToast('update');
				}).catch(error => {
					handleOpenToast('error');
					console.log("erro", error);
				});
			}
		}
	}

	const pickerDateOnly = <Grid item xs={12} sm={6}>
								<Controller
									as={TextField}
									control={control}
									type="date"
									name="DateOnly"
									label="Data"
									margin="normal"
									variant="outlined"
									defaultValue=""
									fullWidth
									// error={errors.EndAt ? true : false}
									// helperText={errors.EndAt && errors.EndAt.message}
									InputProps={{inputProps: { min: "2020-12-01", max: "2100-01-01"} }}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</Grid>;
	
	const pickerHourOnly = <>
						
						<Grid item xs={12} sm={6}>
							<Controller
								as={TextField}
								control={control}
								type="time"
								name="StartAt"
								label="Horário Inicial"
								margin="normal"
								variant="outlined"
								defaultValue=""
								fullWidth
								error={errors.StartAt ? true : false}
								helperText={errors.StartAt && errors.StartAt.message}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Controller
								as={TextField}
								control={control}
								type="time"
								name="EndAt"
								label="Horário Final"
								margin="normal"
								variant="outlined"
								defaultValue=""
								fullWidth
								error={errors.EndAt ? true : false}
								helperText={errors.EndAt && errors.EndAt.message}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
					</>;

	const periodicNew = <Grid item xs={12} sm={6}>
							<FormControl variant="outlined" fullWidth margin="normal">
								<InputLabel>Dias da Semana</InputLabel>
								<Controller
									as={(props) =>
										<Select
											variant="outlined"
											fullWidth
											labelId="weekdays-label"
											label="Dias da Semana"
											id="weekdays"
											multiple
											value={weekdaySelected}
											onChange={(e) => setWeekdaySelected(e.target.value)}
											renderValue={(selected) => (
												<div>
													{selected.map((el) => (
														<Chip key={el} label={el} />
													))}
												</div>
											)}
										>
											<MenuItem key="0" value="Domingo">Domingo</MenuItem>
											<MenuItem key="1" value="Segunda-Feira">Segunda-Feira</MenuItem>
											<MenuItem key="2" value="Terça-Feira">Terça-Feira</MenuItem>
											<MenuItem key="3" value="Quarta-Feira">Quarta-Feira</MenuItem>
											<MenuItem key="4" value="Quinta-Feira">Quinta-Feira</MenuItem>
											<MenuItem key="5" value="Sexta-Feira">Sexta-Feira</MenuItem>
											<MenuItem key="6" value="Sábado">Sábado</MenuItem>
										</Select>
									}

									control={control}
									defaultValue=""
									name="Weekdays"
								>
								</Controller>
							</FormControl>
						</Grid>;

	const periodicEdit = <Grid item xs={12} sm={6}>
						<FormControl variant="outlined" fullWidth margin="normal">
							<InputLabel>Tipo de Restrição</InputLabel>
							<Controller
								as={(props) =>
									<Select
										onChange={(el => setWeekday(el.target.value))}
										name="weekday"
										label="Dia da semana"
										control={control}
										value={weekday}
									>
										<MenuItem value={'0'} key={2}>Domingo</MenuItem>
										<MenuItem value={'1'} key={2}>Segunda-Feira</MenuItem>
										<MenuItem value={'2'} key={2}>Terça-Feira</MenuItem>
										<MenuItem value={'3'} key={2}>Quarta-Feira</MenuItem>
										<MenuItem value={'4'} key={2}>Quinta-Feira</MenuItem>
										<MenuItem value={'5'} key={2}>Sexta-Feira</MenuItem>
										<MenuItem value={'6'} key={2}>Sábado</MenuItem>
									</Select>
								}

								control={control}
								defaultValue=""
								name="weekday"
							>
							</Controller>
						</FormControl>
						</Grid>;

	const punctualView = <>
							{pickerDateOnly}
							{pickerHourOnly}
						</>;

	
	return (
		<Container maxWidth="lg">
			<Paper className="paper" className="form-container">
				<Typography variant="h6">
					Informações Primárias
					</Typography>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Grid container spacing={1}>
						<Grid item xs={12}>
							<FormControl variant="outlined" fullWidth margin="normal">
								<InputLabel>Tipo de Restrição</InputLabel>
								<Controller
									as={(props) =>
										<Select
											onChange={(el => setTypeRestriction(el.target.value))}
											name="RestrictionType"
											label="Tipo de Restrição"
											control={control}
											value={typeRestriction}
										>
											<MenuItem value={'punctual'} key={1}>Pontual</MenuItem>
											<MenuItem value={'periodic_day'} key={2}>Semanal</MenuItem>
										</Select>
									}

									control={control}
									defaultValue=""
									name="RestrictionType"
								>
								</Controller>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={6}>
							<FormControl variant="outlined" fullWidth margin="normal">
								<InputLabel>Eventos</InputLabel>
								<Controller
									as={(props) =>
										<Select
											onChange={(el => setEvent(el.target.value))}
											name="EventID"
											defaultValue=""
											label="Eventos"
											control={control}
											value={event}
										>
											{allEvents.map((el, i) => (
												<MenuItem value={el.ID} key={i}>{el.Name}</MenuItem>
											))}
										</Select>
									}

									control={control}
									defaultValue=""
									name="EventID"
								>
								</Controller>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Controller
								as={TextField}
								name="Name"
								control={control}
								label="Nome"
								margin="normal"
								variant="outlined"
								defaultValue=""
								fullWidth
								error={errors.Name ? true : false}
								helperText={errors.Name && errors.Name.message}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Controller
								as={TextField}
								name="RequiredRealtor"
								control={control}
								label="Quantidade minima de pessoas"
								margin="normal"
								variant="outlined"
								defaultValue=""
								fullWidth
								type="number"
								error={errors.RequiredRealtor ? true : false}
								helperText={errors.RequiredRealtor && errors.RequiredRealtor.message}
							/>
						</Grid>

						
						{typeRestriction == 'punctual' ? punctualView : pickerHourOnly}
						{typeRestriction == 'periodic_day' ? (props.id >= 0 ? periodicEdit : periodicNew) : ''}

						<Grid item xs={12}>
							<Button type="submit" variant="outlined" aria-label="delete" color="primary" >
								Salvar
							</Button>
						</Grid>

					</Grid>
				</form>
			</Paper>
		</Container>
	);
};

export default ScheduleEditPage;