import React from 'react';
import ReportList from './ReportList';
import ReportEdit from './ReportEdit';

import { useParams } from 'react-router-dom';

const Report = () => {
	let params = useParams();
	if(params.edit === 'edit') {
		return <ReportEdit id={params.id} />
	} else {
		return <ReportList />	
	}
};

export default Report;