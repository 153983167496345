import React, { useState, useEffect } from 'react';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';

import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { useToast } from '../../../util/ToastProvider'
import axios from 'axios';

import RealtorRestriction from './RealtorRestriction';

import { compareTwoArrayOfObj } from '../../../util/helpers'

const RealtorEditPage = (props) => {
	const { handleOpenToast } = useToast();

	const [realtorEvents, setRealtorEvents] = useState([]);

	const [allEvents, setAllEvents] = useState([]);
	const [eventsSelected, setEventsSelected] = useState([]);
	const [showComponent, setShowComponent] = useState(false);

	let formSchema = yup.object().shape({
		Name: yup.string().required('Preencha o nome'),
		Email: yup.string().email().required('Preencha o email'),
		CPF: yup.string().required('Preencha o cpf'),
		Phone: yup.string().required('Preencha o telefone'),
		Password: yup.string(),
		Registry: yup.string().required('Preencha o registro'),
	});

	const { control, handleSubmit, errors, setValue } = useForm({
		validationSchema: formSchema
	});


	useEffect(() => {
		const getEvents = (realtorInfo = []) => {
			axios.get(
				`event?page=0&limit=10000&order=id asc`,
			).then(response => {
				let allData = response.data || [];
				setAllEvents(allData);
				if (props.id >= 0) handleGetRealtorEvents(realtorInfo, allData);
			}).catch(error => {
				handleOpenToast('error');
				console.log("erro", error);
			});
		};
		const getNormalData = () => {
			axios.get(
				'realtor/' + props.id,
			).then(async response => {
				let allData = response.data || [];
				setValue([
					{ Name: allData.Name },
					{ CPF: allData.CPF },
					{ Email: allData.Email },
					{ Phone: allData.Phone },
					{ Registry: allData.Registry }
				]);
				await setRealtorEvents(allData || []);
				await getEvents(allData);
				setShowComponent(true);
			}).catch(error => {
				handleOpenToast('error');
				console.log("erro", error);
			});
		}

		if (props.id >= 0) {
			getNormalData();
		} else {
			getEvents();
		}
		const handleGetRealtorEvents = (realtorEvents, allEvents) => {
			if (realtorEvents.Events?.length > 0 && allEvents.length > 0) {
				let itemsEquals = [];
				allEvents.map((el, i) => {
					realtorEvents.Events.map((ell, j) => {
						if (el.ID == ell.EventID) itemsEquals.push(i);
					})
				});
				// let unduplicated = [...new Set(itemsEquals)]
				setEventsSelected(itemsEquals);
			}
		}
	}, [])

	const onSubmit = (values) => {
		let objInputs = {
			Name: values.Name,
			CPF: values.CPF,
			Email: values.Email,
			Phone: values.Phone,
			Registry: values.Registry
		};
		if(values.Password != "") objInputs.Password = values.Password;
		if (props.id >= 0) {
			objInputs.ID = Number(props.id);
			axios.patch(
				'realtor',
				objInputs,
			).then(response => {
				handleEventsRealtor();
				handleOpenToast('update');
			}).catch(error => {
				handleOpenToast('error');
				console.log("erro", error);
			});
		} else {
			axios.post(
				'realtor',
				objInputs,
			).then(response => {
				handleOpenToast('create');
			}).catch(error => {
				handleOpenToast('error');
				console.log("erro", error);
			});
		}
	}
	const handleDeleteEventsRealtor = (eventToDelete) => {
		eventToDelete.map(el => {
			axios.delete(
				'realtor-event/' + el.ID,
				).then(response => {
				}).catch(error => {
					console.log("erro", error, error.response);
				});
		})
	}
	const handleAddEventsRealtor = (eventToAdd) => {
		eventToAdd.map(el => {
			axios.post(
				'realtor-event',
				{
					RealtorID: Number(props.id),
					EventID: Number(el.ID)
				},
			).then(response => {
			}).catch(error => {
				console.log("erro", error);
			});
		});
	}

	const handleEventsRealtor = () => {
		// //pega os eventos que tão selecionados na tela
		let eventsSelectedsInView = [];
		eventsSelected.map(el => eventsSelectedsInView.push(allEvents[el]));

		// //pega os eventos que vieram da api/db
		let eventsReturnedByAPI = [];
		if(realtorEvents.Events?.length > 0) eventsReturnedByAPI = realtorEvents.Events;

		let eventsToDelete, eventsToAdd, comparation;

		if(eventsSelectedsInView.length > 0 || eventsReturnedByAPI.length > 0) {
			// se vier no arrayA tem que remover
			// se vier no arrayB tem que adicionar
			comparation = compareTwoArrayOfObj(eventsReturnedByAPI, eventsSelectedsInView);
			eventsToDelete = comparation.onlyInA;
			eventsToAdd = comparation.onlyInB;
		}

		if(eventsToDelete && eventsToDelete.length > 0) handleDeleteEventsRealtor(eventsToDelete);
		if(eventsToDelete && eventsToAdd.length > 0)    handleAddEventsRealtor(eventsToAdd);

	}


	const eventsAllowed = <Grid item xs={12}>
		<FormControl variant="outlined" fullWidth margin="normal">
			<InputLabel>Tipos de plantões que o corretor está habilitado a realizar</InputLabel>
			<Controller
				as={(props) =>
					<Select
						variant="outlined"
						fullWidth
						label="Tipos de plantões que o corretor está habilitado a realizar"
						id="events"
						multiple
						value={eventsSelected}
						onChange={(e) => setEventsSelected(e.target.value)
						}
						renderValue={(selected) => (
							<div>
								{selected.map((el) => (
									<Chip key={el} label={allEvents[el].Name} />
								))}
							</div>
						)}
					>
						{allEvents.map((el, i) => (
							<MenuItem value={i} key={i}>{el.Name}</MenuItem>
						))}
					</Select>
				}

				control={control}
				defaultValue=""
				name="Events"
			>
			</Controller>
		</FormControl>
	</Grid>;
	return (
		<Container maxWidth="lg">
			<Paper className="paper" className="form-container">
				<Typography variant="h6">Informações Primárias</Typography>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Grid container spacing={1}>
						<Grid item xs={12} sm={4}>
							<Controller
								as={TextField}
								name="Name"
								control={control}
								label="Nome"
								margin="normal"
								variant="outlined"
								defaultValue=""
								fullWidth
								error={errors.Name ? true : false}
								helperText={errors.Name && errors.Name.message}
							/>
						</Grid>
						<Grid item xs={12} sm={4}>
							<Controller
								as={TextField}
								name="CPF"
								control={control}
								label="CPF"
								margin="normal"
								variant="outlined"
								defaultValue=""
								fullWidth
								error={errors.CPF ? true : false}
								helperText={errors.CPF && errors.CPF.message}
							/>
						</Grid>
						<Grid item xs={12} sm={4}>
							<Controller
								as={TextField}
								name="Email"
								control={control}
								label="Email"
								margin="normal"
								variant="outlined"
								defaultValue=""
								fullWidth
								error={errors.Email ? true : false}
								helperText={errors.Email && errors.Email.message}
							/>
						</Grid>
						<Grid item xs={12} sm={4}>
							<Controller
								as={TextField}
								name="Phone"
								control={control}
								label="Telefone"
								margin="normal"
								variant="outlined"
								defaultValue=""
								fullWidth
								error={errors.Phone ? true : false}
								helperText={errors.Phone && errors.Phone.message}
							/>
						</Grid>
						<Grid item xs={12} sm={4}>
							<Controller
								as={TextField}
								name="Password"
								control={control}
								label="Senha"
								margin="normal"
								variant="outlined"
								defaultValue=""
								fullWidth
								error={errors.Password ? true : false}
								helperText={errors.Password && errors.Password.message}
							/>
						</Grid>
						<Grid item xs={12} sm={4}>
							<Controller
								as={TextField}
								name="Registry"
								control={control}
								label="Registro"
								margin="normal"
								variant="outlined"
								defaultValue=""
								fullWidth
								error={errors.Registry ? true : false}
								helperText={errors.Registry && errors.Registry.message}
							/>
						</Grid>
						{
							showComponent ?
								<>
									{eventsAllowed}
									<RealtorRestriction paramID={props.id} restrictionsSelected={realtorEvents.Restrictions} />
								</>
							:
								null
						}
						<Grid item xs={12}>
							<Button type="submit" variant="outlined" aria-label="delete" color="primary" >Continuar</Button>
						</Grid>

					</Grid>
				</form>
			</Paper>
		</Container>
	);
};

export default RealtorEditPage;