import i18next from "i18next";

i18next
	.init({
		interpolation: {
			// React already does escaping
			escapeValue: false,
		},
		lng: "pt", // "en" | "es"
		// Using simple hardcoded resources for simple example
		resources: {
			en: {
				translation: {
					'age': "Years",
					'name': "Name",
					'admin': "Admin",
					'email': "Email",
					'senha': "password",
					'recuperar_senha': "Recover Password?",
					'login': "log into",
					'nome': "Name",
					'codigo': "Code",
					'moeda': "Currency",
					'save': "Save",
					'date_birth': "Date of birth",
					'initial_test_dna': "Initial DNA Testing",
					'generate': "generate",
					'partner_birthday_date': "Partner's Date of Birth",
					'translation': "Translation",
					'user_password_error': "Incorrect Username or Password!",
					'novo': "New",
					'nome': "Name",
					'senha': "password",
					'email': "Email",
					'tipo_de_acesso': "Access Type",
					'adiministrador': "Manager",
					'recepsionista': "Receptionist",
					'salvar': "Save",
					'linguagem': "Language",
					'slug': "Slug",
					'categoria': "Category",
					'textos': "Texts",
					'controle': "Control",
					'clientes': "Customers",
					'relatorios': "Reporting",
					'relatorio': "Report",
					'administrativo': "Administrative",
					'usuarios': "Users",
					'config_conta': "Config. Account",
					'logout': "Logout",
					'id': "ID",
					'ver_colunas': "View Columns",
					'ediçao': "Edition",
					'checkbox': "Checkbox",
					'multiple': "Multiple",
					'desculpe_nenhum_registro_encontrado': "Sorry, No Records Found.",
					'ordenar': "Order",
					'proxima_pagina': "Next page",
					'pagina_anterior': "previous page",
					'linhas_por_pagina': "Lines Per Page",
					'de': "In",
					'buscar': "Search",
					'download_csv': "Download CSV",
					'imprimir': "Print out",
					'filtrar_tabela': "Filter_Table",
					'tudo': "All",
					'filters': "FILTERS",
					'reset': "RESET",
					'mostrar_Colunas': "Show Columns",
					'mostrar_esconder_colunas_das_tabelas': "Show / hide Table Columns",
					'linha_s_seleciona_das': "Selected Line (s)",
					'inativar_linhas': "Inactivate Lines",
					'delete_selected_rows': "Delete Selected Rows",
					'idcategory': "IDCategory",
					'logout': "Logout",
					'order': "Order",
					'created_at': "Created on",
					'linhas_selecionadas': "Selected Lines",
					'slug': "Slug",
					'waiting': "Waiting",
					'paid_at': "Paid at",
					'buy_at': "Buy at",
					'select_category': "Select the Category",
					'configuration': "Configuration",
					'time_to_receive_email': "Time to receive the client receive the DNA email",
					'position': 'Posição',
					'corretor': 'Corretores',
					'restricao-geral': 'Restrições Gerais',
				},
			},
			pt: {
				translation: {
					'restricao-do-corretor': 'Restrição do Corretor',
					'corretor': 'Corretor',
					'dashboard': "Dashboard",
					'user-group': "Grupo de Usuário",
					'company': "Empresa",
					'age': "Anos",
					'name': "Nome",
					'admin': "Admin",
					'email': "Email",
					'senha': "Senha",
					'recuperar_senha': "Recuperar Senha?",
					'login': "Logar",
					'nome': "Nome",
					'codigo': "Código",
					'moeda': "Moeda",
					'save': "Salvar",
					'date_birth': "Data de Nascimento",
					'initial_test_dna': "Teste Inicial de DNA",
					'generate': "Gerar",
					'partner_birthday_date': "Data de Nascimento do parceiro(a)",
					'translation': "Tradução",
					'user_password_error': "Usuário ou Senha incorreta!",
					'novo': "Novo",
					'nome': "Nome",
					'senha': "Senha",
					'email': "Email",
					'tipo_de_acesso': "Tipo de Acesso",
					'adiministrador': "Adiministrador",
					'recepsionista': "Recepsionista",
					'salvar': "Salvar",
					'linguagem': "Linguagem",
					'slug': "Slug",
					'categoria': "Categoria",
					'textos': "Textos",
					'controle': "Controle",
					'clientes': "Clientes",
					'relatorios': "Relatórios",
					'relatorio': "Relatório",
					'administrativo': "Administrativo",
					'usuarios': "Usuários",
					'config_conta': "Config. de Conta",
					'logout': "Logout",
					'id': "ID",
					'ver_colunas': "Ver Colunas",
					'ediçao': "Edição",
					'checkbox': "Checkbox",
					'multiple': "Multiple",
					'desculpe_nenhum_registro_encontrado': "Desculpe, Nenhum Registro Encontrado.",
					'ordenar': "Ordenar",
					'proxima_pagina': "Proxima Página",
					'pagina_anterior': "página Anterior",
					'linhas_por_pagina': "Linhas por Página",
					'de': "De",
					'buscar': "Buscar",
					'download_csv': "Download CSV",
					'imprimir': "Imprimir",
					'filtrar_tabela': "Filtrar_Tabela",
					'tudo': "Tudo",
					'filters': "FILTERS",
					'reset': "RESET",
					'mostrar_Colunas': "Mostrar Colunas",
					'mostrar_esconder_colunas_das_tabelas': "Mostrar/esconder Colunas das Tabelas",
					'linha_s_seleciona_das': "Linha(s) Seleciona(das)",
					'inativar_linhas': "Inativar Linhas",
					'delete_selected_rows': "Delete Selected Rows",
					'idcategory': "IDCategory",
					'logout': "Logout",
					'order': "Ordem",
					'created_at': "Criado em",
					'linhas_selecionadas': "Linhas Selecionadas",
					'slug': "Slug",
					'paid': "Pago",
					'paid_at': "Pago em",
					'buy_at': "Comprado em",
					'waiting': "Aguardando",
					'select_category': "Seleciona a categoria",
					'configuration': "Configurações",
					'time_to_receive_email': "Tempo para receber o cliente receber o email DNA",
					'position': 'Posição',
					'restricao-geral': 'Restrições Gerais',
				},
			},
			es: {
				translation: {
					'age': "Años",
					'name': "Nombre",
					'admin': "Administrador",
					'email': "Correo electrónico",
					'senha': "Contraseña",
					'recuperar_senha': "¿Recuperar contraseña?",
					'login': "Iniciar sesión",
					'nome': "Nombre",
					'codigo': "Código",
					'moeda': "Moneda",
					'save': "Guardar",
					'date_birth': "Fecha de nacimiento",
					'initial_test_dna': "Prueba inicial de ADN",
					'generate': "Generar",
					'partner_birthday_date': "Fecha de nacimiento de la pareja",
					'translation': "Traducción Inglés",
					'user_password_error': "Nombre de usuario o contraseña incorrectos!",
					'novo': "Nuevo",
					'nome': "Nombre",
					'senha': "Contraseña",
					'email': "Correo electrónico",
					'tipo_de_acesso': "Tipo de acceso",
					'adiministrador': "Gerente",
					'recepsionista': "Recepcionista",
					'salvar': "Guardar",
					'linguagem': "Idioma",
					'slug': "Babosa",
					'categoria': "Categoría:",
					'textos': "Textos",
					'controle': "Control",
					'clientes': "Clientes",
					'relatorios': "Informes",
					'relatorio': "Informe",
					'administrativo': "Administrativo",
					'usuarios': "Los usuarios",
					'config_conta': "Config. Cuenta",
					'logout': "Cerrar sesión",
					'id': "ID",
					'ver_colunas': "Ver columnas",
					'ediçao': "Edicion",
					'checkbox': "Casilla de verificación",
					'multiple': "Múltiples",
					'desculpe_nenhum_registro_encontrado': "Lo sentimos, no se encontraron registros.",
					'ordenar': "Ordenar",
					'proxima_pagina': "Página siguiente",
					'pagina_anterior': "Página anterior",
					'linhas_por_pagina': "Líneas por página",
					'de': "De",
					'buscar': "Buscar",
					'download_csv': "Descargar CSV",
					'imprimir': "Imprimir",
					'filtrar_tabela': "Filter_Table",
					'tudo': "Todo",
					'filters': "FILTROS",
					'reset': "RESET",
					'mostrar_Colunas': "Mostrar columnas",
					'mostrar_esconder_colunas_das_tabelas': "Mostrar / ocultar columnas de tabla",
					'linha_s_seleciona_das': "Línea (s) seleccionada (s)",
					'inativar_linhas': "Inactivar líneas",
					'delete_selected_rows': "Eliminar filas seleccionadas",
					'idcategory': "IDCategory",
					'logout': "Cerrar sesión",
					'order': "Orden",
					'created_at': "Creado en",
					'linhas_selecionadas': "Lineas Seleccionadas",
					'slug': "Slug",
					'waiting': "Esperando",
					'paid_at': "Pagado en",
					'buy_at': "Comprado en",
					'select_category': "Seleccione la categoria",
					'configuration': "configuración",
					'time_to_receive_email': "Hora de recibir al cliente recibir el correo electrónico de ADN",
					'position': 'Posición',
					'restricao-geral': 'Restrições Gerais',
				},
			},
		},
	})

export default i18next;