import React from 'react';
import { useParams } from 'react-router-dom';

import UserListPage from './UserList';
import UserEditPage from './UserEdit';

const UserPage = () => {
	let params = useParams();
	if(params.edit === 'edit') {
		return <UserEditPage id={params.id} />
	} else {
		return <UserListPage />	
	}
};

export default UserPage;