import React from 'react';
import { useParams } from 'react-router-dom';

import EventListPage from './EventList';
import EventEditPage from './EventEdit';

const EventPage = () => {
	let params = useParams();
	if(params.edit === 'edit') {
		return <EventEditPage id={params.id} />
	} else {
		return <EventListPage />	
	}
};

export default EventPage;